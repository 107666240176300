

const saveExpiry = (deadline: string) => {
    window.localStorage.setItem('expiry', deadline);
}

const getExpiry = () => {
    let expiry = window.localStorage.getItem('expiry');
    if (expiry) {
        return new Date(expiry);
    } else {
        return new Date();
    }
}

const saveQuestion = (questionId : number) => {
    window.localStorage.setItem('questionId', questionId.toString());
}

const getQuestion = () => {
    return window.localStorage.getItem('questionId');
}

const setHBTime = (hbTime : number) => {
    window.localStorage.setItem('hbTime', String(hbTime));
}

const getHBTime = () => {
    if (window.localStorage.getItem('hbTime') == null) {
        return 0;
    }
    return Number(window.localStorage.getItem('hbTime'));
}

export {
    saveExpiry,
    getExpiry,
    saveQuestion,
    getQuestion,
    setHBTime,
    getHBTime
};