import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Logo from '../assets/images/logo.png'
import Title from './Title';
import CountdownTimer from'./CountdownTimer';
import InputArea from './InputArea';
import Button from './Button';
import {saveExpiry, saveQuestion, getQuestion, setHBTime} from '../helpers/question';
import {answerQuestion, askQuestion, fetchTeamList, signIn, fetchQuestionCount, getQuestionInfo} from '../api';
import { getExpiry } from '../helpers/question';
import './QuestionPage.scss';
import Popup from "./Popup";

export default function QuestionPage({ws, role, token,  onAuth, registerMsgCallback} : {token: string | null,
      ws?: WebSocket | undefined,
      role: string | null,
      onAuth: ({role, token} : {role: string, token: string}) => void,
      registerMsgCallback: (cb : {cb: (e : MessageEvent) => void})=> void}) {
  let [questionId, setQuestionId] = useState(getQuestion());
  let [login, setLogin] = useState('');
  let [password, setPassword] = useState('');
  let [questionText, setQuestionText] = useState('Добро пожаловать!');
  let [team, setTeam] = useState<string>('');
  let [teams, setTeams] = useState([])
  let [isQuestionActive, setQuestionActive] = useState<boolean>(false);
  let [currentQuestion, setCurrentQuestion] = useState<number>(0);


  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const onClose = () => {
    setShowPopup(false);
    setPopupContent("")
    navigate(`/`);
  }

  const navigate = useNavigate();

  const auth = async () => {
    let { data } = await signIn(login, password);
    onAuth(data)
  }

  const updateLogin = (event : React.ChangeEvent<HTMLInputElement>) : void => {
    setLogin(event.target.value);
  }

  const updatePassword = (event : React.ChangeEvent<HTMLInputElement>) : void => {
    setPassword(event.target.value);
  }

  const onSubmit = async (text : string, team: string = '') => {
    if (role === 'jury') {
      try {
        await askQuestion(text, team);
        setCurrentQuestion((currentQuestion || 0) + 1)
        setQuestionActive(true);
      } catch(e) {
        console.log(e);
      }
    }

    if (role === 'user' && questionId) {
      try {
        let {status} = await answerQuestion(questionId, text);
        if (status === 200) {
          navigate('/team');
        }
      } catch (error: any) {
        let res = error.response
        console.log(res.data)
        setPopupContent(res.data);
        setShowPopup(true);
      }

    }
  }

  const onCountdownFinished = () => {
    if (role === 'user') navigate('/team');
    if (role === 'jury') getQuestionCount().then(r => {});
    setQuestionActive(false);
  }

  const getTeamList = async () => {
    let { data } = await fetchTeamList();
    setTeams(data);
  }

  const getQuestionCount = async () => {
    let { data } = await fetchQuestionCount();
    setCurrentQuestion(data.count)
  }

  useEffect(() => {
    if (role === 'checker') navigate('/questions');
    if (role === 'user' && getExpiry().getTime() < new Date().getTime()) navigate('/team');
    
    if (role === 'jury') getTeamList();
  }, [role, token]);

  useEffect(() => {
    if (role === 'jury' && getExpiry().getTime() > new Date().getTime()) setQuestionActive(true);
    if (role === 'jury') getQuestionCount();
  }, []);

  async function restoreQuestionNotExpired(data: any) {
    if (!data && !('deadline' in data && 'questionId' in data)) {
      return
    }
    if (Math.max(Math.round((Date.parse(data.deadline) - new Date().getTime()) / 1000), 0) == 0) {
      onCountdownFinished();
    } else {
      if (role === 'jury') {
        try {
          setCurrentQuestion(data.questionId)
          setQuestionActive(true);
        } catch (e) {
          console.log(e);
        }
      }
      if (role === 'user') {
        let questionInfo = await getQuestionInfo(data.questionId);
        if (questionInfo.status === 200) {
            navigate('/team');
        }
      }
    }
  }

  useEffect(() => {
    console.log('ws question page', ws);
    if (ws) {
      const wsListener = (event : MessageEvent) => {
        if (!event) {
          return
        }
        let response = JSON.parse(event.data);
        if(response.type === 'question') {
          console.log('onmessage', response);
          setQuestionId(response.data.questionId);
          setQuestionText(response.data.text);
          saveQuestion(response.data.questionId);
          saveExpiry(response.data.deadline);
          // if connection restored and question time is expired
          if (Math.max(Math.round((Date.parse(response.data.deadline) - new Date().getTime()) / 1000), 0) == 0) {
            onCountdownFinished();
          }

          restoreQuestionNotExpired(response.data)
        } else if (response.type === 'heart_beat_expired') {
            onCountdownFinished();
        } else {
          console.log('onmessage', response);
        }
      }
      registerMsgCallback({cb: wsListener});
    }
  }, [ws]);

  if (!token) return (
    <div className="loginForm">
      <div className="loginForm-inputs">
        <input className="loginForm-input" value={login} onInput={updateLogin} type="text" placeholder="Login"></input>
        <input className="loginForm-input" value={password} onInput={updatePassword} type="password" placeholder="Password"></input>
      </div>
      <Button
        onClick={() => auth()}
        text='Войти'
      />
    </div>
  )

  if (role === 'screen') return (
    <div className="screen">
      <div className="screen-column">
        <img
          src={Logo}
          alt="logo"
          className="screen-logo"
        ></img>
      </div>
      <div className="screen-column">
        <CountdownTimer
          onCountdownFinished={onCountdownFinished}
        />
      </div>
      <div className="screen-question">
        {questionText}
      </div>
    </div>
  )

  return (
    <div className="main">
        <img
          src={Logo}
          alt="logo"
          className="main-logo"
        />
        <CountdownTimer
          onCountdownFinished={onCountdownFinished}
        />
        <InputArea
          onSubmit={onSubmit}
          buttonText={ role === 'user' ? 'Отправить' : 'Задать вопрос'}
          isJury={ role === 'jury' }
          currentQuestion={currentQuestion}
          teams={teams}
          team={team}
          setTeam={setTeam}
          isQuestionActive={isQuestionActive}
        />
        <Title/>
        {showPopup && (
            <Popup content={popupContent} onClose={onClose} />
        )}
    </div>
  )
}
