import React from 'react';

import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import './Button.scss';

export default function Button({text, onClick=()=>{}, isSubmit=false, disabled}: {text: string, onClick?: (text: string) => Promise<void> | void, isSubmit?: boolean, disabled?: boolean}) {
  return (
    <button
      disabled={disabled}
      className={`button ${text === '' ? 'button--square' : ''}`}
      onClick={() => onClick(text)}
      type={`${isSubmit ? 'submit' : 'button'}`}
    >
        <span>{ text }</span>
        <ArrowIcon className="button-arrow"/>
    </button>
  );
}
