import React from 'react';
import './TeamHeader.scss';

export default function TeamHeader({teamName, points, totalQuestions}: {teamName: string, points: number, totalQuestions: number}) {
  return (
    <div className="header">
      <div className="header-column">
        <div className="header-label">Ваша команда:</div>
        <div className="header-name">{teamName}</div>
      </div>
      <div className="header-column">
        <div className="header-label">Ваши баллы:</div>
        <span className="header-points">
          <span className="header-points--inner">{points}/</span>{totalQuestions}
        </span>
      </div>
    </div>
  );
}
