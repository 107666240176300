import React from 'react';
import './TeamRanking.scss';

export default function TeamRanking({ranking, division, divisions, questionCount, onChange} : {ranking : Array<{ name: string, points: number, weightedPoints: number }>, division?: string, divisions: string[], questionCount: number, onChange: (div: string) => void}) {
  return (
    <div className='ranking'>
      <div className='ranking-label'>Рейтинг команд:</div>
      <div>
        <select
          className="ranking-select"
          onChange={e => onChange(e.target.value)}
          value={division}
        >
          {
            divisions.map(divisionName => (
              <option
                key={divisionName}
                value={divisionName}
              >{divisionName}</option>
            ))
          }
        </select>
      </div>
      <div className='ranking-teams'>
      {
        ranking.map((team, index) => (
          <div 
            key={index}
            className='ranking-team'
          >
            <span>{index + 1}. {team.name}</span>
            <span className="ranking-line"></span>
            <span>{team.points}/{questionCount}</span>
            <span className="ranking-difficulty"> (сложность: {team.weightedPoints.toFixed(2)})</span>
          </div>
        ))
      }
      </div>
    </div>
  );
}
