import React from 'react';

import { QuestionByTeam } from './Judges/Answers';
import './Answer.scss';

export interface Answer {
  id: string,
  answer: string,
  correct: boolean | null | string
}

export interface Question {
  text: string,
  id?: number,
  answer: Answer
}

const getAnswerClass = (isCorrect: string | boolean | null) => {
  switch(isCorrect) {
    case 'true': return 'answer--correct';
    case 'false': return 'answer--wrong';
    case 'unknown': return 'answer--unknown';
    default: return '';
  }
}

export default function AnswerComponent({ answer } : { answer: Answer } ) {
  if (answer) return (
    <div
      className={`answer ${getAnswerClass(answer.correct)}`}
    >
      {answer.answer}
    </div>
  )
  return <div className="answer--wrong">-</div>;
}
