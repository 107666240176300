import React, { useEffect, useState } from 'react';
import { useNavigate, useParams  } from "react-router-dom";
import { fetchAnswers, updateAnswerRating } from '../../api';

import Button from '../Button';
import Answers from './Answers';
import Popup from '../Popup';

import { QuestionByTeam } from './Answers';
import './AnswersPage.scss';

export default function AnswersPage() {
  const [questionData, setQuestionData] = useState<QuestionByTeam>({
    text: '',
    id: 0,
    answers: []
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const onClose = () => {
    setShowPopup(false);
    setPopupContent("")
    navigate(`/`);
  }

  let { questionId } = useParams();
  let navigate = useNavigate();

  const updateData = async (answerId: string, isCorrect: boolean | string) => {
    await updateAnswerRating({answerId, isCorrect});
    
    let index = questionData.answers.findIndex(el => el.id === answerId);
    let newTeamAnswer = {
      ...questionData.answers[index],
      correct: isCorrect,
    }

    let newQD = {
      ...questionData,
      answers: [
        ...questionData.answers.slice(0, index),
        newTeamAnswer,
        ...questionData.answers.slice(index + 1)
      ]
    }

    setQuestionData(newQD);
  }

  useEffect(() => {
    const getAnswers = async () => {
      if (questionId) {
        try {
          let {data} = await fetchAnswers(questionId);
          setQuestionData(data);
        } catch (error: any) {
          let res = error.response
          console.log(res.data)
          setPopupContent(res.data);
          setShowPopup(true);
        }
      }
    }
    getAnswers();
  }, []);

  return (
    <div className="juryPage">
      <div className="juryPage-title">{questionData.id}. {questionData.text}</div>
      <Answers 
        questionData={questionData}
        updateData={updateData}
      />
      <Button
        onClick={() => navigate('/questions')}
        text="Назад"
      />
      {showPopup && (
          <Popup content={popupContent} onClose={onClose} />
      )}
    </div>

  );
}
