import React from 'react';
import { AnswerByTeam } from './Answers';
import { ReactComponent as CorrectIcon } from '../../assets/icons/check.svg';
import { ReactComponent as WrongIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg';
import './CorrectToggle.scss';

export default function CorrectToggle({ answer, onToggle } : { answer: AnswerByTeam, onToggle: (answerId: string, isCorrect: boolean | string) => void }) {
  const inactiveClass = (isCorrect : boolean | null | string) => {
    return answer.correct !== isCorrect ? 'correctToggle-icon--inactive' : '';
  }

  return (
    <div className="correctToggle">
      <CorrectIcon
        className={`correctToggle-icon correctToggle-icon--correct ${inactiveClass('true')}`}
        onClick={() => onToggle(answer.id, 'true')}
      />
      <QuestionIcon
        className={`correctToggle-icon correctToggle-icon--unknown ${inactiveClass('unknown')}`}
        onClick={() => onToggle(answer.id, 'unknown')}
      />
      <WrongIcon
        className={`correctToggle-icon correctToggle-icon--wrong ${inactiveClass('false')}`}
        onClick={() => onToggle(answer.id, 'false')}
      />
    </div>
  );
}
