import React from 'react';
import './TeamAnswers.scss';

import AnswerComponent, { Question } from './Answer';

export default function TeamAnswers({questions} : { questions : Question[]}) {
  return (
    <div className='answers'>
      <div className='answers-label'>Ваши ответы:</div>
      <div className='answers-questions'>
      {
        questions.map((question, index) => (
          <div 
            key={index}
            className='answers-question'
          >
            {question.id}. {question.text}
            <AnswerComponent
              answer={question.answer}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
