import React from 'react';
import AnswerComponent, { Answer } from '../Answer';
import CorrectToggle from './CorrectToggle';

import './Answers.scss';

export interface AnswerByTeam extends Answer {
  team: string,
  teamId: string
}

export interface QuestionByTeam {
  text: string,
  id: number,
  answers: Array<AnswerByTeam>
}

export default function Answers({questionData, updateData} : {questionData: QuestionByTeam, updateData: (answerId: string, isCorrect: boolean | string) => void}) {
  return (
    <div className='answersByTeam'>
      <div
        className='answersByTeam-answers'>
      {
        questionData.answers.map((answer, index) => (
          <div
            key={index}
            className='answersByTeam-teamAnswer'
          >
            <div className='answersByTeam-answer'>
              <div className='answersByTeam-team'>{answer.team}</div>
              <AnswerComponent
                answer={answer}
              />
            </div>
            <CorrectToggle
              answer={answer}
              onToggle={updateData}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
