import React, { useState, useEffect } from 'react';
import { getExpiry } from '../helpers/question';
import './CountdownTimer.scss';

export default function CountdownTimer({ onCountdownFinished } : { onCountdownFinished : () => void }) {
  const deadline = getExpiry();
  const getTimeLeft = () => Math.max(Math.round((deadline.getTime() - new Date().getTime()) / 1000), 0);

  const [seconds, setSeconds] = useState<number>(getTimeLeft());

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      console.log(getTimeLeft());
      setSeconds(getTimeLeft());
    }, 100);

    if (!seconds) {
      clearInterval(countdownInterval);
      onCountdownFinished();
    }

    return () => clearInterval(countdownInterval);
  }, [seconds]);

  useEffect(() => {
    setSeconds(getTimeLeft());
  }, [getTimeLeft])

  return (
    <div className="countdown">
      <div className="countdown-label">
        Осталось времени:</div>
      <div className="countdown-time">
        <div className="countdown-number">{Math.max(seconds - 20, 0)}</div>
        <div className="countdown-label countdown-label--inner">секунд</div>
      </div>
      <div className="countdown-time countdown-time--right">
        <div className="countdown-number">{Math.min(seconds, 20)}</div>
        <div className="countdown-label countdown-label--inner">секунд</div>
      </div>
    </div>
  );
}
