import axios, { AxiosRequestConfig } from 'axios';

let baseUrl = new URL(window.location.href).origin
if(`${process.env.NODE_ENV}` == "development") {
    baseUrl = new URL(`${process.env.REACT_APP_BASE_URL}`).origin;
}
axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('token');
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => Promise.reject(error),
);

axios.interceptors.response.use(function (response) {
    return response;
  }, (error) => {
    if (error.response.status === 401) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('role');
        document.location.reload();
    }
    return Promise.reject(error);
  }
);

const signIn = async (login: string, password: string) => {
    return await axios.post(`${baseUrl}/auth`, {
        login: login,
        password: password
    });
}

const fetchTeamInfo = async () => {
    return await axios.get(`${baseUrl}/team/info`);
}

const fetchTeamRanking = async (division?: string) => {
    return await axios.get(`${baseUrl}/jury/ranking`, { params: { division } });
}

const updateAnswerRating = async ({answerId, isCorrect} : { answerId : string, isCorrect : boolean | string }) => {
    return await axios.put(`${baseUrl}/jury/rate`, {
        answerId,
        correct: isCorrect
    });
};

const fetchQuestions = async () => {
    return await axios.get(`${baseUrl}/jury/questions`);
}

const fetchQuestionCount = async () => {
    return await axios.get(`${baseUrl}/jury/questions-count`);
}

const fetchAnswers = async (questionId: string) => {
    return await axios.get(`${baseUrl}/jury/answers/${questionId}`);
}

const fetchTeamList = async () => {
    return await axios.get(`${baseUrl}/jury/teams`);
}

const askQuestion = async (question: string, team : string) => {
    return await axios.post(`${baseUrl}/jury/ask-question`, {
        text: question,
        teamId: team
    });
};

const answerQuestion = async(questionId: string, answer: string) => {
    return await axios.post(`${baseUrl}/team/answer`, {
        questionId,
        answer
    });
};

const getQuestionInfo = async(questionId: string) => {
    return await axios.post(`${baseUrl}/team/answer-info`, {
        questionId
    });
};


export {
    signIn,
    fetchTeamInfo,
    fetchTeamRanking,
    updateAnswerRating,
    fetchAnswers,
    fetchQuestions,
    fetchQuestionCount,
    fetchTeamList,
    askQuestion,
    answerQuestion,
    getQuestionInfo
}
