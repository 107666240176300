import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import TeamRanking from './TeamRanking';
import Button from './Button';
import './RankingPage.scss';
import { fetchTeamRanking } from '../api';

export default function RankingPage() {
  const [ranking, setRanking] = useState([]);
  const [division, setDivision] = useState<string>();
  const [divisions, setDivisions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);

  let navigate = useNavigate();

  const getRanking = async (division?: string) => {
    console.log('updating ranking', division);
    let { data } = await fetchTeamRanking(division);
    setRanking(data.teams);
    setDivision(data.division);
    setDivisions(data.divisions);
    setQuestionCount(data.questionCount);
  };

  const onChange = (div?: string) => {
    if (div) setDivision(div);
  }

  useEffect(() => {
    getRanking(division);
  }, [division]);

  return (
    <div className="team">
      <TeamRanking 
        ranking={ranking}
        division={division}
        divisions={divisions}
        questionCount={questionCount}
        onChange={onChange}
      />
      <Button 
        onClick={() => navigate('/questions')}
        text='Назад'
      />
    </div>
  );
}
