import React from 'react';

import './Title.scss';

export default function Title() {
  return (
    <span className="title">
      <span>Что?</span>
      <span className="title-white">Где?</span>
      <span>Когда?</span>
    </span>
  );
}
